<template>
<div class="team">
<h1 class="subheading grey--text">Team</h1>
<v-container class="my-5">
    <v-row>
        <v-col xs="12" sm="6" md="4" lg="3" v-for="person in team" :key="person.name">
            <v-card class="text-center ma-3">
                <v-responsive class="pt-4">
                    <v-avatar size="100" class="grey lighten-2">
                        <img :src="person.avator">
                    </v-avatar>
                </v-responsive>
                <v-card-text>
                    <div class="subheading">{{ person.name }}</div>
                    <div class="grey--text">{{ person.role }}</div>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="grey">
                        <v-icon small left>mdi-message</v-icon>
                        <span>Message</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>

</div>
</template>

<script>

export default {
    data() {
        return {
            team: [
                { name: 'The Net Ninja', role: 'Web Developer' , avator: '/avatar-1.png'},
                { name: 'Ryu', role: 'Graphic designer', avator: '/avatar-2.png'},
                { name: 'Chun li', role: 'Web developer', avator: '/avatar-3.png'},
                { name: 'Gouken', role: 'Social media maverick', avator: '/avatar-4.png'},
                { name: 'Yoshi', role: 'Sales guru', avator: '/avatar-5.png'},
            ]
        }
    }
}
</script>
